<template>
  <div class='resubRedirect--main'>
    <b-spinner
      v-if='isLoading'
      label="Large Spinner">
    </b-spinner>
  </div>
</template>

<script>
// import storage from '@/storage'
import { mapMutations, mapActions } from 'vuex'
import { errorPrompt } from '@/prompts'
import storage from '@/storage'
export default {
  name: 'resubmission-redirect',
  data () {
    return {
      isRedirected: false,
      isLoading: true
    }
  },
  methods: {
    ...mapMutations({
      setErrorWithResubmissionToken: 'SET_ERROR_WITH_RESUBMISSION_TOKEN'
    }),
    ...mapActions({
      getSupplementary: 'getSupplementary',
      getResubmissionData: 'getResubmissionData'
    }),
    handleSSOLoginSuccess (resubmissionToken) {
      this.isLoading = false
      this.$router.push({ name: 'resubmission-form' })
    },
    handleSSOLoginError (error) {
      this.isRedirected = false
      this.isLoading = false
      if (error) {
        const errorOptions = {
          confirmButtonText: this.$i18n.t('swal.ok-button')
        }
        if (error.response.status === 403) {
          errorPrompt(this.$i18n.t('swal.login.resubmissionAlreadyDone'), errorOptions)
          this.$router.push({ name: 'home' })
        } else if (error.response.status === 410) {
          errorPrompt(this.$i18n.t('swal.resubmission-form.reject-error-403-dublicate'), errorOptions)
          this.$router.push({ name: 'home' })
        } else if (error.response.status === 406) {
          errorPrompt(this.$i18n.t('swal.resubmission-form.reject-error-406-periodIsOver'), errorOptions)
          this.$router.push({ name: 'home' })
        } else if (error.response.status === 412) {
          errorPrompt(this.$i18n.t('swal.resubmission-form.reject-error-412-studentInactive'), errorOptions)
          this.$router.push({ name: 'home' })
        } else {
          this.setErrorWithResubmissionToken(true)
          return this.$router.push({ name: 'home' })
        }
      }
    }
  },
  created () {
    this.isRedirected = 'token' in this.$route.query
    if (this.isRedirected) {
      storage.set.lang(this.$route.params.lng)
      const resubmissionToken = this.$route.query.token
      this.getSupplementary(resubmissionToken)
        .then(() => {
          this.getResubmissionData(resubmissionToken)
            .then(res => {
              this.handleSSOLoginSuccess(resubmissionToken)
            })
            .catch(err => {
              this.handleSSOLoginError(err)
            })
        })
        .catch(err => {
          this.handleSSOLoginError(err)
        })
    }
  }
}
</script>

<style lang='scss' scoped>
.resubRedirect--main {
  position: relative;
}
.spinner-border {
  position: absolute;
  top: 70%;
  left: 45%;
  width: 5rem;
  height: 5rem;
  border: 0.4em solid white;
  border-right-color: transparent;
}
.mt-app--box {
  min-height: 30vh;
  background-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
